<template>
  <list-layout>
    <template slot="search">
      <b-col cols="4">
        <div class="position-relative">
          <b-form-input v-model="search" placeholder="Search" class="pr-12" @keyup.enter="searchName = search" />
          <font-awesome-icon
            :icon="['fas', 'search']"
            fixed-width
            class="input-search text-primary"
            @click="searchName = search"
          />
        </div>
      </b-col>
    </template>
    <template slot="actions">
      <b-button variant="secondary" class="btn-action btn-width text-primary inline">
        <font-awesome-icon :icon="['fas', 'plus']" fixed-width />
      </b-button>
    </template>
    <template slot="list">
      <b-table
        ref="table"
        hover
        borderless
        :items="sortDirectoryList"
        :fields="fields"
        @row-hovered="rowHovered"
        @row-unhovered="rowUnhovered"
      >
        <template #head(name)>
          <div class="cursor-pointer" @click="sortList('name')">
            NAME
            <font-awesome-icon :icon="['fas', 'sort']" fixed-width />
          </div>
        </template>
        <template #head(role)>
          <div class="cursor-pointer" @click="sortList('role')">
            ROLE
            <font-awesome-icon :icon="['fas', 'sort']" fixed-width />
          </div>
        </template>
        <template #head(email)>
          <div class="cursor-pointer" @click="sortList('email')">
            EMAIL
            <font-awesome-icon :icon="['fas', 'sort']" fixed-width />
          </div>
        </template>
        <template #head(extension)>
          <div class="cursor-pointer" @click="sortList('extension')">
            EXTENSION
            <font-awesome-icon :icon="['fas', 'sort']" fixed-width />
          </div>
        </template>
        <template #head(status)>
          <div class="cursor-pointer" @click="sortList('status')">
            STATUS
            <font-awesome-icon :icon="['fas', 'sort']" fixed-width />
          </div>
        </template>
        <template #head(action)>
          <div />
        </template>
        <template #cell(status)="data">
          <span v-if="data.item.status === 0" class="d-inline-block bg-primary text-white rounded-pill px-2 py-0">
            Pending
          </span>
          <span v-else class="d-inline-block bg-success text-white rounded-pill px-2 py-0">
            Active
          </span>
        </template>
        <template #cell(action)="data">
          <b-button
            v-show="isHovered(data.item)"
            variant="primary"
            class="btn-action btn-width inline"
            style="box-shadow:none;width:2rem;height:2rem"
          >
            <font-awesome-icon :icon="['fas', 'pen']" fixed-width />
          </b-button>
        </template>
      </b-table>
    </template>
  </list-layout>
</template>

<script>
import listLayout from '@/components/layouts/listLayout.vue'
export default {
  name: 'Directory',
  components: {
    listLayout
  },
  data () {
    return {
      search: '',
      searchName: '',
      hoveredRow: null,
      field: 'name',
      ascending: true,
      directoryList: [
        {
          id: 1,
          name: 'Virgie Rios',
          role: 'Owner',
          email: 'ethyl_gaylord@wehner.ca',
          extension: '202',
          status: 1
        },
        {
          id: 2,
          name: 'Lucas Alvarez',
          role: 'Technician',
          email: 'darren.moen@hotmail.com',
          extension: '202',
          status: 1
        },
        {
          id: 3,
          name: 'Myra Lamb',
          role: 'Admin',
          email: 'swift.karen@gmail.com',
          extension: '202',
          status: 1
        },
        {
          id: 4,
          name: 'Hettie Burke',
          role: 'Member',
          email: 'firstnamelast@company.com',
          extension: '202',
          status: 1
        },
        {
          id: 5,
          name: 'Elnora Woods',
          role: 'Member',
          email: 'rosetta.lueilwitz@immanuel.co.uk',
          extension: '202',
          status: 1
        }
      ]
    }
  },
  computed: {
    fields () {
      return ['name', 'role', 'email', 'extension', 'status', 'action']
    },
    filterDirectoryList () {
      return this.directoryList.reduce((list, directory) => {
        if (this.searchName && directory.name.indexOf(this.searchName) === -1) return list
        list.push({
          id: directory.id,
          name: directory.name,
          role: directory.role,
          email: directory.email,
          extension: directory.extension,
          status: directory.status
        })
        return list
      }, [])
    },
    sortDirectoryList () {
      return this.filterDirectoryList.slice().sort((a, b) => {
        const dataA = String(a[this.field]).toLowerCase()
        const dataB = String(b[this.field]).toLowerCase()
        if (dataA > dataB) return this.ascending ? 1 : -1
        if (dataA < dataB) return this.ascending ? -1 : 1
        else return 0
      })
    }
  },
  created () {
    // this.getClinicList()
  },
  methods: {
    // getClinicList () {
    //   getPartnerList().then(res => {
    //     this.partnerList = res.data.data
    //   })
    // },
    sortList (field) {
      if (this.field === field) {
        this.ascending = !this.ascending
      } else {
        this.field = field
        this.ascending = true
      }
    },
    rowHovered (row) {
      this.hoveredRow = row
      this.$refs.table.refresh()
    },
    isHovered (item) {
      return item === this.hoveredRow
    },
    rowUnhovered () {
      this.hoveredRow = null

      this.$refs.table.refresh()
    }
  }
}
</script>
<style lang="scss">

</style>
